import * as React from "react";
import styled from "@emotion/styled";
import { padding, margin } from "polished";
import { IconProps } from "./Icons";

type StatusProps = {
  type: "success" | "error" | "neutral";
  icon: React.FC<IconProps>;
};

const Text = styled.div<Pick<StatusProps, "type">>(({ theme, type }) => ({
  ...padding(8, 16),
  ...margin(12, 0),
  ...theme.typography.CaptionM,
  borderRadius: theme.borders.radius.small,
  backgroundColor: {
    neutral: theme.colors.gray300,
    error: theme.colors.red,
    success: theme.colors.aqua,
  }[type],
  color: theme.colors.white,
  display: "flex",
}));

const IconWrapper = styled.div(({ theme }) => ({
  paddingRight: 8,
  color: theme.colors.white,
}));

export const Status: React.FC<StatusProps> = ({
  children,
  type,
  icon: Icon,
}) => (
  <Text type={type}>
    <IconWrapper>
      <Icon size="medium" />
    </IconWrapper>
    {children}
  </Text>
);
