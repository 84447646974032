import * as React from "react";
import styled from "@emotion/styled";
import { Title } from "../../components/Typography";
import { Image } from "../../components/Image";
import Volunteer2 from "../../assets/photos/volunteer2.jpg";
import { padding } from "polished";
import { Link } from "../../components/Link";
import { urls } from "../../helpers/urls";

const ImageWrapper = styled.div(({ theme }) => ({
  paddingLeft: 32,
  maxWidth: 340,
  width: "40%",
  flexShrink: 0,
  [theme.breakpoints.phone]: {
    maxWidth: "none",
    width: "100%",
    ...padding(20, 0),
  },
}));

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.phone]: {
    flexDirection: "column-reverse",
  },
}));

export const Volunteer: React.FC = () => (
  <>
    <Title>Volunteering</Title>
    <Wrapper>
      <div>
        We are experts in bringing people together to share life experiences and
        to learn from each other. We have matched elderly musicians with young
        singers, blind seniors with volunteers who enjoy reading aloud to them,
        young families with seniors who enjoy grandparenting, and seniors who
        enjoy teaching knitting and other crafts to their volunteers.
        <ul>
          <li>
            <Link href={urls.volunteer.getInvolved} variation="highlighted">
              Get involved
            </Link>
          </li>
          <li>
            <Link href={urls.volunteer.applications} variation="highlighted">
              Applications
            </Link>
          </li>
          <li>
            <Link href={urls.volunteer.caseNotes} variation="highlighted">
              Submit client case notes
            </Link>
          </li>
          <li>
            <Link href={urls.volunteer.faqs} variation="highlighted">
              Volunteer FAQs
            </Link>
          </li>
          <li>
            <Link href={urls.volunteer.quotes} variation="highlighted">
              Volunteer quotes
            </Link>
          </li>
        </ul>
      </div>
      <ImageWrapper>
        <Image src={Volunteer2} alt="Volunteer with senior" />
      </ImageWrapper>
    </Wrapper>
  </>
);
