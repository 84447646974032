import * as React from "react";
import styled, { CSSObject } from "@emotion/styled";
import { border, padding } from "polished";

const Wrapper = styled.div({
  flexGrow: 1,
});

const Required = styled.span(({ theme }) => ({
  color: theme.colors.red,
}));

const Label = styled.label(({ theme }) => ({
  ...theme.typography.BodyM,
  display: "block",
  marginBottom: 4,
}));

type SelectProps = {
  hasError?: boolean;
};

const Select = styled.select<SelectProps>(({ theme, hasError }) => ({
  ...theme.typography.Body,
  ...border(1, "solid", hasError ? theme.colors.red : theme.colors.gray100),
  ...padding(6, 4),
  borderRadius: theme.borders.radius.xsmall,
  width: "100%",
  ...({
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    appearance: "none",
    background:
      "url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%",
  } as CSSObject),
}));

const Error = styled.div(({ theme }) => ({
  ...theme.typography.Caption,
  color: theme.colors.red,
  paddingTop: 4,
}));

type SelectFieldProps = {
  name: string;
  value: string;
  onChange: (payload: { name: string; value: string }) => void;
  options: { label: string; value: string }[];
  required?: boolean;
  error?: string;
};

export const SelectField: React.FC<SelectFieldProps> = ({
  name,
  value,
  onChange,
  required,
  error,
  options,
}) => {
  const handleOnChange = React.useCallback(
    (e) => {
      onChange({ name, value: e.target.value });
    },
    [onChange, name]
  );
  return (
    <Wrapper>
      <Label>
        <label htmlFor={name}>
          {name}
          {required ? (
            <>
              {" "}
              <Required>*</Required>
            </>
          ) : null}
        </label>
      </Label>
      <Select
        name={name}
        value={value}
        onChange={handleOnChange}
        hasError={!!error}
        {...{
          "aria-label": name,
          "aria-required": required,
        }}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
