import * as React from "react";
import { Title } from "../../components/Typography";
import styled from "@emotion/styled";
import { timelineContent } from "./content/timelineContent";
import { Event } from "./Event";
import { border, padding } from "polished";

const Wrapper = styled.div(({ theme }) => ({
  ...padding(24, 20),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.phone]: {
    ...padding(16, 0),
    ...border("top", 1, "solid", theme.colors.gray),
    backgroundColor: theme.colors.white,
    marginTop: 20,
  },
}));

export const History: React.FC = () => {
  return (
    <>
      <Title>History</Title>
      <p>
        Visiting Neighbors was founded in 1972 by a group of Greenwich Village
        residents who were concerned about their elderly neighbors who were
        being discharged from the hospital and going home with no support
        network in place. Many seniors were being readmitted to the hospital
        because they fell, did not follow medical advice, or weren't eating
        right because they were unable to go shopping or were feeling isolated,
        sad and/or unmotivated. As the program flourished, word spread
        throughout the community about the concept of “neighbors helping elderly
        neighbors,” and Village Visiting Neighbors was born. Volunteers were
        screened and carefully matched with seniors wanting companionship, based
        on mutual interests, similar backgrounds, personality and compatibility,
        and a friendly visiting program was created.
      </p>
      <p>
        <i>
          “Village Visiting Neighbors is an effective, intelligent group of
          people who represent the best in voluntary social activism…whose
          friendly visitors fulfill a significant function in the development of
          a network of care”
        </i>
      </p>
      <p>
        - Dr. Philip Bricker, Director, Department of Community Medicine at St.
        Vincent’s Hospital & Medical Center.
      </p>
      <Wrapper>
        <Event center="1972" />
        {timelineContent.map((event, i) => (
          <Event
            {...event}
            key={`${event.title}-${event.year}`}
            isLeftToRight={i % 2 === 0}
          />
        ))}
        <Event center="Today" />
      </Wrapper>
    </>
  );
};
