import { CSSObject } from "@emotion/styled";

export type Typography = {
  [key in
    | "accent"
    | "Header"
    | "HeaderB"
    | "Subtitle"
    | "Title"
    | "BodyM"
    | "BodyB"
    | "Body"
    | "CaptionM"
    | "CaptionB"
    | "Caption"]: CSSObject;
};

export enum ViewportSizes {
  Desktop = 1800,
  Phone = 600,
  SmallDesktop = 960,
  TabletLandscape = 1200,
  TabletPortrait = 900,
}

function generateMediaQueryString(viewportSize: ViewportSizes): string {
  return `@media (max-width: ${viewportSize}px)`;
}

const typography: Typography = {
  accent: {
    fontFamily: '"DM Serif Text", serif',
  },
  Title: {
    fontSize: 40,
    lineHeight: "60px",
    fontFamily: '"DM Serif Text", serif',
  },
  Subtitle: {
    fontSize: 28,
    lineHeight: "40px",
    fontWeight: 800,
  },
  Header: {
    fontSize: 20,
    lineHeight: "32px",
    fontWeight: 600,
  },
  HeaderB: {
    fontSize: 20,
    lineHeight: "32px",
    fontWeight: 800,
  },
  Body: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
  },
  BodyM: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
  },
  BodyB: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
  },
  Caption: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
  },
  CaptionM: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600,
  },
  CaptionB: {
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 700,
  },
};

export const defaultTheme = {
  breakpoints: {
    desktop: generateMediaQueryString(ViewportSizes.Desktop),
    phone: generateMediaQueryString(ViewportSizes.Phone),
    smallDesktop: generateMediaQueryString(ViewportSizes.SmallDesktop),
    tabletLandscape: generateMediaQueryString(ViewportSizes.TabletLandscape),
    tabletPortrait: generateMediaQueryString(ViewportSizes.TabletPortrait),
  },
  animations: { standard: "100ms" },
  colors: {
    aqua: "#1380A6",
    aqua50: "#B8D9E4",
    aqua100: "#71B3CA",
    aqua200: "#4299B8",
    red: "#cd2b31",
    yellow: "#f5d90a",
    gray: "#f2f2f2",
    gray100: "#D0D0D0",
    gray200: "#a0a0a0",
    gray300: "#505050",
    white: "#FFFFFF",
    black: "#171717",
  },
  shadows: {
    pageContentShadow: `2px 2px 12px rgba(0,0,0,0.25)`,
    hoverShadow: `0 0 25px rgba(0,0,0,0.05)`,
    menuDropShadow: `0 4px 24px rgba(0,0,0,0.15)`,
    cardShadow: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)`,
  },
  zIndex: {
    base: 0,
    bump: 100,
    overlay: 4000,
    modalMask: 6999,
    modal: 7000,
    popover: 8000,
    tooltip: 9000,
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  borders: {
    radius: {
      default: 10,
      xsmall: 2,
      small: 4,
      large: 16,
      xlarge: 20,
    },
  },
  typography,
};

export type Theme = typeof defaultTheme;
