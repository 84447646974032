import * as React from "react";
import { Link } from "../../components/Link";
import { urls } from "../../helpers/urls";
import { email, phone } from "../../helpers/constants";
import volunteerImage from "../../assets/photos/volunteer.jpg";
import styled from "@emotion/styled";
import { margin } from "polished";
import { Title, Subtitle } from "../../components/Typography";
import { SideBySide } from "../../components/SideBySide";

const Wrapper = styled.div({
  ...margin(20, 0),
});

export const GetInvolved: React.FC = () => {
  return (
    <>
      <Title>Get involved</Title>
      <Wrapper>
        <Subtitle>Interested in volunteering with us?</Subtitle>
        <SideBySide
          align="top"
          imgSrc={volunteerImage}
          imgAlt="Woman with volunteer"
        >
          <p>
            Fill out the{" "}
            <Link variation="highlighted" href={urls.volunteer.applications}>
              Volunteer Application
            </Link>{" "}
            and send it to{" "}
            <Link variation="highlighted" href={`mailto:${email}`}>
              {email}
            </Link>
            .
          </p>
          <p>
            As an interview is required, you can expedite the process by
            completing your application, obtaining two completed letters of
            reference, and calling Howie Square at <b>{phone}</b> to tell him
            you have all of your paperwork prepared.
          </p>
          If you have any questions, feel free to give us a call!
        </SideBySide>
      </Wrapper>
      <Subtitle>Ongoing programs</Subtitle>
      <p>
        <b>Friendly visiting</b>
        <br />
        With a friendly visit, seniors enjoy a few hours of company each week,
        offering both the senior and volunteer an opportunity to learn from one
        another. Each visit is as unique as the two individuals involved.
        Seniors and volunteers share memories and warm conversation; they may
        listen to music together, watch a favorite movie or enjoy a hobby or a
        game of cards. Volunteers benefit from learning about an older person's
        life experiences, and seniors enjoy an opportunity to share their views
        with younger generations and stay connected with the outside world.
      </p>
      <p>
        <b>Shop & escort</b>
        <br />
        For a senior, crossing New York City's busy streets can be an
        overwhelming challenge. Our volunteers help seniors make trips to the
        grocery store or pharmacy. When the weather is bad, or the senior is not
        up to a trip outside, volunteers can go shopping for them. We escort
        seniors to medical appointments to provide them with both a steady arm
        to lean on and emotional support. On nice days, our volunteers may walk
        outside with seniors for exercise, fresh air and companionship.
      </p>
      <Subtitle>Board membership</Subtitle>
      <p>
        We are always on the lookout for new Board members. If you would like to
        learn more about joining our Board, please call Dr. Cynthia Maurer,
        Executive Director, at {phone}. Presently, VN could utilize a person(s)
        with legal and/or marketing expertise.
      </p>
      <Subtitle>Special skills</Subtitle>
      <p>
        Do you have special skills to share? We welcome all extra help,
        including (but not limited to):
        <ul>
          <li>Art, graphic design</li>
          <li>Computer skills</li>
          <li>Fund raising</li>
          <li>Marketing/PR</li>
          <li>Music</li>
          <li>Office help</li>
          <li>Photography</li>
          <li>Recreation, crafts</li>
          <li>
            Special projects and events (holiday gift delivery, street fairs,
            etc.)
          </li>
          <li>Writing</li>
        </ul>
      </p>
      <p>
        <Subtitle>Other</Subtitle>
      </p>
      <p>
        <b>Days of service</b>
        <br />
        Howie Square, our volunteer coordinator, can help corporate teams plan
        days of service or other opportunities for participation. This can be a
        few hours, a whole day, or a one-time project, like our Holiday Elfing.
        Please contact him at {phone} to discuss potential corporate
        involvement.
      </p>
      <p>
        <b>School organizations</b>
        <br />
        Schools may also reach out to our organization through either
        school-sponsored activities, or individual participation. For example,
        in the past, schools have made holiday and Valentine's cards for
        seniors. There is no such thing as too much help.
      </p>
    </>
  );
};
