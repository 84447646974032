import * as React from "react";
import { Title } from "../../components/Typography";
import styled from "@emotion/styled";
import { useForm } from "../../hooks/useForm";
import { Button } from "../../components/Button";
import { File } from "../../components/Icons";
import { InputField } from "../../components/InputField";
import { SelectField } from "../../components/SelectField";
import { phone } from "../../helpers/constants";
import { padding } from "polished";
import ClientCaseNotes from "../../assets/ClientCaseNotes.pdf";
import { border } from "polished";

export const Divider = styled.div(({ theme }) => ({
  paddingTop: 24,
  marginTop: 24,
  ...border("top", 1, "solid", theme.colors.gray),
}));

const Icon = styled.span({
  paddingRight: 4,
});

const ButtonContent = styled.span({
  display: "inline-flex",
  alignItems: "center",
  whiteSpace: "nowrap",
});

const InlineFieldsWrapper = styled.div(({ theme }) => ({
  display: "grid",
  gap: 32,
  gridTemplateColumns: "1fr 1fr 1fr",
  paddingBottom: 24,
  [theme.breakpoints.tabletPortrait]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 16,
  },
}));

const FieldWithButton = styled.div({
  display: "flex",
  alignItems: "flex-end",
});

const FieldButton = styled.div({
  ...padding(0, 0, 2, 8),
});

const FieldWrapper = styled.div({
  paddingBottom: 16,
});

enum Fields {
  date = "Date",
  start = "Start time",
  end = "End time",
  client = "Client name",
  volunteer = "Volunteer/student name",
  activity = "Nature of activity",
  shopActivity = "Shop & escort activity",
  aids = "Mobility aids used (if any)",
  physical = "Physical condition",
  apartment = "Apartment condition",
  mood = "Client's emotional state/mood",
  comments = "Overall experience and additional comments",
  signature = "Signature",
}

const fields = [
  Fields.date,
  Fields.start,
  Fields.end,
  Fields.client,
  Fields.volunteer,
  Fields.activity,
  Fields.shopActivity,
  Fields.aids,
  Fields.physical,
  Fields.apartment,
  Fields.mood,
  Fields.comments,
  Fields.signature,
];

type FormType = { [key in Fields]: string };
const defaultForm = fields.reduce(
  (map, field) => ({
    ...map,
    [field]: "",
  }),
  {} as FormType
);

enum Activity {
  friendly = "Friendly visiting",
  shop = "Shop & escort",
}

const activityOptions = [
  { label: "", value: "" },
  { label: "Friendly visiting", value: Activity.friendly },
  { label: "Shop & escort", value: Activity.shop },
];

const shopActivityOptions = [
  { label: "", value: "" },
  ...[
    "Friendly Visiting",
    "Shopping",
    "Therapeutic Walking",
    "Escort to/from Doctor/Dentist",
    "Escort to/from Medical Treatment",
    "Escort to/from Physical Therapy",
    "Escort - Other",
    "Telephone Reassurance",
    "Seasonal Activities & Special Projects",
  ].map((activity) => ({ label: activity, value: activity })),
];

const downloadPdf = () => window.open(ClientCaseNotes);

export const CaseNotes: React.FC = () => {
  const { REACT_APP_EMAILJS_TEMPLATEID_CASENOTES } = process.env as any;
  const [form, setForm] = React.useState<FormType>(defaultForm);
  const isShopActivity = form[Fields.activity] === Activity.shop;
  const errors = React.useMemo(() => {
    const baseErrors = fields.reduce(
      (map, field) => ({
        ...map,
        [field]: !form[field] ? "Required" : "",
      }),
      {} as FormType
    );
    return {
      ...baseErrors,
      [Fields.shopActivity]:
        !form[Fields.shopActivity] && isShopActivity ? "Required" : "",
    };
  }, [form, isShopActivity]);
  const hasFormErrors = Object.values(errors).some(Boolean);

  const {
    isSubmitting,
    status,
    hasClickedSubmit,
    onSubmit,
    recaptchaComponent,
  } = useForm({
    templateParams: {
      date: form[Fields.date],
      start: form[Fields.start],
      end: form[Fields.end],
      client: form[Fields.client],
      volunteer: form[Fields.volunteer],
      activity: `${form[Fields.activity]}${
        isShopActivity ? ` - ${form[Fields.shopActivity]}` : ""
      }`,
      aids: form[Fields.aids],
      physical: form[Fields.physical],
      apartment: form[Fields.apartment],
      mood: form[Fields.mood],
      comments: form[Fields.comments],
    },
    templateId: REACT_APP_EMAILJS_TEMPLATEID_CASENOTES,
    setForm,
    defaultForm,
    hasFormErrors,
    recaptchaSiteKey: "REACT_APP_RECAPTCHA_SITE_KEY_NOTES_INVISIBLE",
  });

  const displayErrors = React.useMemo(
    () =>
      fields.reduce(
        (map, field) => ({
          ...map,
          [field]: hasClickedSubmit ? errors[field] : "",
        }),
        {} as FormType
      ),
    [errors, hasClickedSubmit]
  );

  const onFieldChange = React.useCallback(
    ({ name, value }: { name: string; value: string }) => {
      setForm({
        ...form,
        [name]: value,
      });
    },
    [setForm, form]
  );

  const onClickToday = React.useCallback(() => {
    onFieldChange({
      name: Fields.date,
      value: new Date().toLocaleDateString(),
    });
  }, [onFieldChange]);

  return (
    <>
      <Title>Client case notes</Title>
      <p>
        Client case notes will be submitted to Howie Square. Please give him a
        call at {phone} if you have any questions.
      </p>
      <p>
        Prefer to print the form and bring it to the office?{"  "}
        <Button onClick={downloadPdf} size="small">
          <ButtonContent>
            <Icon>
              <File size="small" />
            </Icon>
            Download PDF
          </ButtonContent>
        </Button>
      </p>
      <Divider />
      <form>
        <InlineFieldsWrapper>
          <FieldWithButton>
            <InputField
              onChange={onFieldChange}
              name={Fields.date}
              value={form[Fields.date]}
              error={displayErrors[Fields.date]}
              required
            />
            <FieldButton>
              <Button onClick={onClickToday} size="small">
                Today
              </Button>
            </FieldButton>
          </FieldWithButton>
          <InputField
            onChange={onFieldChange}
            name={Fields.start}
            value={form[Fields.start]}
            error={displayErrors[Fields.start]}
            required
          />
          <InputField
            onChange={onFieldChange}
            name={Fields.end}
            value={form[Fields.end]}
            error={displayErrors[Fields.end]}
            required
          />
        </InlineFieldsWrapper>
        <InlineFieldsWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.client}
            value={form[Fields.client]}
            error={displayErrors[Fields.client]}
            required
          />
          <InputField
            onChange={onFieldChange}
            name={Fields.volunteer}
            value={form[Fields.volunteer]}
            error={displayErrors[Fields.volunteer]}
            required
          />
        </InlineFieldsWrapper>
        <InlineFieldsWrapper>
          <SelectField
            options={activityOptions}
            onChange={onFieldChange}
            name={Fields.activity}
            value={form[Fields.activity]}
            error={displayErrors[Fields.activity]}
            required
          />
          {isShopActivity && (
            <SelectField
              options={shopActivityOptions}
              onChange={onFieldChange}
              name={Fields.shopActivity}
              value={form[Fields.shopActivity]}
              error={displayErrors[Fields.shopActivity]}
              required
            />
          )}
        </InlineFieldsWrapper>
        <FieldWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.aids}
            value={form[Fields.aids]}
            type="textarea"
            error={displayErrors[Fields.aids]}
            rows={2}
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.physical}
            value={form[Fields.physical]}
            type="textarea"
            error={displayErrors[Fields.physical]}
            rows={6}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.apartment}
            value={form[Fields.apartment]}
            type="textarea"
            rows={6}
            error={displayErrors[Fields.apartment]}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.mood}
            value={form[Fields.mood]}
            type="textarea"
            rows={6}
            error={displayErrors[Fields.mood]}
            required
          />
        </FieldWrapper>
        <FieldWrapper>
          <InputField
            onChange={onFieldChange}
            name={Fields.comments}
            value={form[Fields.comments]}
            type="textarea"
            rows={6}
            error={displayErrors[Fields.comments]}
            required
          />
        </FieldWrapper>
        <InlineFieldsWrapper>
          <div></div>
          <div></div>
          <InputField
            onChange={onFieldChange}
            name={Fields.signature}
            value={form[Fields.signature]}
            error={displayErrors[Fields.signature]}
            format="cursive"
            required
          />
        </InlineFieldsWrapper>
        <Button
          onClick={onSubmit}
          disabled={Object.values(displayErrors).some(Boolean)}
          loading={isSubmitting}
        >
          Submit
        </Button>
        {status}
        {recaptchaComponent}
      </form>
    </>
  );
};
