import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "../../components/Link";
import { urls } from "../../helpers/urls";
import { padding } from "polished";
import Kids from "../../assets/photos/kids.jpg";
import { Title } from "../../components/Typography";
import { Image } from "../../components/Image";

const ImageWrapper = styled.div(({ theme }) => ({
  paddingLeft: 64,
  maxWidth: 340,
  width: "40%",
  flexShrink: 0,
  [theme.breakpoints.phone]: {
    maxWidth: "none",
    width: "100%",
    ...padding(20, 0),
  },
}));

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.phone]: {
    flexDirection: "column-reverse",
  },
}));

export const Programs: React.FC = () => {
  return (
    <>
      <Title>Programs</Title>
      <Wrapper>
        <div>
          Every match Visiting Neighbors makes is as unique as the two people
          involved. We have thousands of success stories of seniors and
          volunteers whose lives have been enriched by their involvement with
          Visiting Neighbors programs:
          <ul>
            <li>
              <Link
                href={urls.programs.friendlyVisiting}
                variation="highlighted"
              >
                Friendly visiting and Shop & Escort
              </Link>
            </li>
            <li>
              <Link
                href={urls.programs.healthAndWellness}
                variation="highlighted"
              >
                Health & wellness
              </Link>
            </li>
            <li>
              <Link
                href={urls.programs.specialOccasions}
                variation="highlighted"
              >
                Special occasions
              </Link>
            </li>
            <li>
              <Link
                href={urls.programs.therapeuticWalking}
                variation="highlighted"
              >
                Therapeutic walks
              </Link>
            </li>
            <li>
              <Link
                href={urls.programs.telephoneReassurance}
                variation="highlighted"
              >
                Telephone reassurance
              </Link>
            </li>
            <li>
              <Link href={urls.programs.faqs} variation="highlighted">
                Program FAQs
              </Link>
            </li>
            <li>
              <Link href={urls.programs.resources} variation="highlighted">
                Resources
              </Link>
            </li>
          </ul>
        </div>
        <ImageWrapper>
          <Image src={Kids} alt="Volunteers, staff, and kids" />
        </ImageWrapper>
      </Wrapper>
    </>
  );
};
