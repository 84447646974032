import * as React from "react";
import { useHistory } from "react-router-dom";
import { border, padding, transparentize } from "polished";
import styled from "@emotion/styled";
import { Link } from "../../../components/Link";
import { urls } from "../../../helpers/urls";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Button } from "../../../components/Button";
import { useTheme } from "@emotion/react";
import { siteMap } from "../content/siteMap";
import { NavigationItem } from "./NavigationItem";
import { MobileNavigation } from "./MobileNavigation";
import { mobileNavHeight } from "../types";
import { FullLogo } from "../../../components/Logos";
import { Facebook, LinkedIn } from "../../../components/Icons";

const SocialIcons = styled.div({
  display: "flex",
  flexGrow: 1,
  flexBasis: "50%",
  justifyContent: "flex-end",
});

const SocialIcon = styled.div({
  marginLeft: 16,
});

const Wrapper = styled.div<{ isHome?: boolean }>(({ theme, isHome }) => ({
  ...border("bottom", 2, "solid", theme.colors.yellow),
  ...padding(20, 32),
  backgroundColor: transparentize(isHome ? 0.1 : 0, theme.colors.aqua),
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadows.pageContentShadow,
  position: "relative",
  zIndex: isHome ? theme.zIndex.overlay : theme.zIndex.base,
  [theme.breakpoints.tabletLandscape]: {
    ...padding(16, 24),
  },
  [theme.breakpoints.tabletPortrait]: {
    ...padding(12, 20),
    height: mobileNavHeight,
  },
}));

const ImageWrapper = styled.div({
  flexShrink: 0,
  display: "block",
});

const ButtonWrapper = styled.div({
  paddingLeft: 16,
  display: "flex",
  gap: 16,
  alignItems: "center",
});

const Navigation = styled.div({
  display: "flex",
  flexGrow: 1,
});

export const TopNavigation: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const isTabletLandscape = useBreakpoint(theme.breakpoints.tabletLandscape);
  const isTabletPortrait = useBreakpoint(theme.breakpoints.tabletPortrait);
  const iconSize = isTabletLandscape ? "medium" : "large";
  const pathname = history.location.pathname;

  const buttonSize = React.useMemo(() => {
    if (isTabletPortrait) {
      return "small";
    }
    return isTabletLandscape ? "medium" : "large";
  }, [isTabletLandscape, isTabletPortrait]);

  return (
    <Wrapper
      isHome={pathname.indexOf(urls.home) === 0}
      role="navigation"
      aria-label="Main navigation"
    >
      <ImageWrapper>
        <Link href={urls.home} ariaLabel="Link to home">
          <FullLogo
            width={isTabletLandscape ? 120 : 220}
            height={isTabletLandscape ? 30 : 55}
            aria-hidden="true"
          />
        </Link>
      </ImageWrapper>
      <Navigation>
        {isTabletPortrait ? (
          <div />
        ) : (
          <>
            {siteMap.map(
              (item) =>
                (item.locations ?? []).indexOf("top") > -1 && (
                  <NavigationItem {...item} key={item.href} />
                )
            )}
          </>
        )}
      </Navigation>
      <ButtonWrapper>
        <SocialIcons>
          <SocialIcon>
            <Link
              variation="light"
              href={urls.linkedIn}
              ariaLabel="Visiting Neighbors LinkedIn page"
            >
              <LinkedIn size={iconSize} />
            </Link>
          </SocialIcon>
          <SocialIcon>
            <Link
              variation="light"
              href={urls.facebook}
              ariaLabel="Visiting Neighbors Facebook page"
            >
              <Facebook size={iconSize} />
            </Link>
          </SocialIcon>
        </SocialIcons>
        <Button href={urls.donate} size={buttonSize} styling="accent">
          Donate
        </Button>
      </ButtonWrapper>
      {isTabletPortrait && (
        <ButtonWrapper>
          <MobileNavigation />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
