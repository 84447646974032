import * as React from "react";
import styled from "@emotion/styled";
import { border, padding } from "polished";
import { siteMap } from "../content/siteMap";
import { Link } from "../../../components/Link";
import { useTheme } from "@emotion/react";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { phone, email } from "../../../helpers/constants";
import { urlToPageTitle } from "../../../helpers/urlToPageTitle";

const AddressWrapper = styled.div(({ theme }) => ({
  paddingBottom: 20,
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 0,
  [theme.breakpoints.phone]: {
    border: 0,
    marginTop: 0,
    paddingTop: 0,
    display: "flex",
    flexDirection: "column-reverse",
    gap: 0,
  },
}));

const Address = styled.div(({ theme }) => ({
  ...theme.typography.CaptionM,
  textAlign: "right",
  flexGrow: 1,
  flexBasis: "50%",
  [theme.breakpoints.phone]: {
    textAlign: "left",
  },
}));

const Wrapper = styled.div(({ theme }) => ({
  ...border("top", 2, "solid", theme.colors.black),
  ...padding(28, 48),
  backgroundColor: theme.colors.gray,
  display: "flex",
  width: "100%",
  position: "relative",
  gap: 24,
  [theme.breakpoints.tabletLandscape]: {
    ...padding(28, 32),
  },
  [theme.breakpoints.phone]: {
    ...padding(28, 32, 32, 32),
  },
}));

const SiteMap = styled.div({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
});

const LinkWrapper = styled.div({
  marginBottom: 8,
  whiteSpace: "nowrap",
});

const Sublink = styled.span(({ theme }) => ({
  ...theme.typography.CaptionM,
  color: theme.colors.gray300,
}));

const Section = styled.div(({ theme }) => ({
  ...padding(0, 48, 20, 0),
  minWidth: 175,
}));

export const BottomNavigation: React.FC = () => {
  const theme = useTheme();
  const isPhone = useBreakpoint(theme.breakpoints.phone);
  return (
    <Wrapper role="navigation" aria-label="Footer">
      {!isPhone && (
        <SiteMap>
          {siteMap.map(
            ({ href, children, locations }) =>
              (locations ?? []).indexOf("bottom") > -1 && (
                <Section key={href}>
                  <LinkWrapper>
                    <Link href={href}>{urlToPageTitle[href]}</Link>
                  </LinkWrapper>
                  {children?.map(
                    ({ href: subHref }) =>
                      !!subHref && (
                        <LinkWrapper key={subHref}>
                          <Link href={subHref}>
                            <Sublink>{urlToPageTitle[subHref]}</Sublink>
                          </Link>
                        </LinkWrapper>
                      )
                  )}
                </Section>
              )
          )}
        </SiteMap>
      )}
      <AddressWrapper>
        <Address>
          3 Washington Square Village, Suite&nbsp;1F
          <br />
          New York, NY 10012
          <br />
          {phone}
          <p>
            <Link href={`mailto:${email}`} variation="highlighted">
              {email}
            </Link>
          </p>
        </Address>
      </AddressWrapper>
    </Wrapper>
  );
};
