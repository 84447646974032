import * as React from "react";
import styled from "@emotion/styled";
import { MenuIcon, Close } from "../../../components/Icons";
import { FullLogo } from "../../../components/Logos";
import { useToggle } from "../../../hooks/useToggle";
import { siteMap } from "../content/siteMap";
import { padding, border, transparentize } from "polished";
import { MobileNavigationItem } from "./MobileNavigationItem";
import { urls } from "../../../helpers/urls";
import { Link } from "../../../components/Link";
import { mobileNavHeight } from "../types";
import { Button } from "../../../components/Button";
import { useBodyScrollLock } from "../../../hooks/useBodyScrollLock";
import { keyframes } from "@emotion/react";
import { urlToPageTitle } from "../../../helpers/urlToPageTitle";

const IconButton = styled.button(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  padding: 4,
  margin: 0,
  color: theme.colors.white,
  cursor: "pointer",
  width: 28,
  display: "flex",
  justifyContent: "center",
  "&:focus-visible": {
    outline: "none",
    boxShadow: `0 0 0 2px ${theme.colors.white}`,
    borderRadius: theme.borders.radius.large,
  },
  "&:focus:not(:focus-visible)": {
    outline: "none",
  },
}));

const slide = keyframes({
  "0%": { transform: "translateY(-8px)" },
  "100%": { transform: "translateY(0px)" },
});

const MenuOuter = styled.div(({ theme }) => ({
  height: "100%",
  width: "100vw",
  maxHeight: "100vh",
  maxWidth: "100%",
  overflow: "scroll",
  zIndex: theme.zIndex.overlay,
  position: "fixed",
  bottom: 0,
  left: 0,
  backgroundColor: transparentize(0.2, theme.colors.black),
}));

const MenuInner = styled.div(({ theme }) => ({
  marginBottom: 32,
  paddingBottom: 32,
  borderBottomLeftRadius: 16,
  borderBottomRightRadius: 16,
  backgroundColor: theme.colors.gray,
}));

const Menu = styled.div(({ theme }) => ({
  animation: `${slide} ${theme.animations.standard} cubic-bezier(0.250, 0.460, 0.450, 0.940)`,
}));

const Header = styled.div(({ theme }) => ({
  ...border("bottom", 2, "solid", theme.colors.yellow),
  ...padding(12, 20),
  boxShadow: theme.shadows.pageContentShadow,
  background: theme.colors.aqua,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  color: theme.colors.white,
  height: mobileNavHeight,
}));

const ImageWrapper = styled.div({
  flexShrink: 0,
  display: "block",
  flexGrow: 1,
});

const ButtonWrapper = styled.div({
  ...padding(0, 16),
});

export const MobileNavigation: React.FC = () => {
  const { on: isMenuOn, toggle: toggleMenu } = useToggle();
  useBodyScrollLock(isMenuOn);
  return (
    <>
      <IconButton onClick={toggleMenu} aria-label="Open menu">
        <MenuIcon size="medium" />
      </IconButton>
      {isMenuOn && (
        // <Overlay>
        <MenuOuter>
          <MenuInner>
            <Header>
              <ImageWrapper>
                <Link href={urls.home} ariaLabel="Link to home">
                  <FullLogo width={120} height={30} aria-hidden="true" />
                </Link>
              </ImageWrapper>
              <ButtonWrapper>
                <Button href={urls.donate} size="small" styling="accent">
                  Donate
                </Button>
              </ButtonWrapper>
              <IconButton onClick={toggleMenu} aria-label="Close menu">
                <Close size="small" />
              </IconButton>
            </Header>
            <Menu>
              {siteMap.map(({ href, children = [] }) => (
                <>
                  <MobileNavigationItem variation="primary" href={href}>
                    {urlToPageTitle[href]}
                  </MobileNavigationItem>
                  {children.map(
                    ({ href: subHref }) =>
                      !!subHref && (
                        <MobileNavigationItem
                          variation="secondary"
                          href={subHref}
                        >
                          {urlToPageTitle[subHref]}
                        </MobileNavigationItem>
                      )
                  )}
                </>
              ))}
            </Menu>
          </MenuInner>
        </MenuOuter>
        // </Overlay>
      )}
    </>
  );
};
